<template>
  <div
    class="navigation navigation--left"
    :style="`width: ${isMini ? width.mini : navWidth}px`"
    v-if="Object.keys(enabledItems).length > 0"
  >
    <div class="drag-handle" @mousedown="onMouseDown" @mouseup="onMouseUp" v-if="!isMini" />

    <v-navigation-drawer permanent touchless :width="navWidth" :rail="isMini" :rail-width="width.mini" class="sidebar bg-background">
      <v-list class="bg-background text-on-background" :class="isMini && 'pt-12'" rounded>
        <div v-if="!isMini">
          <v-list-subheader class="text-subtitle-2 text-on-neutral">{{ appName }}</v-list-subheader>
          <v-divider class="mx-4 bg-on-neutral" />
        </div>

        <template v-for="links in enabledItems">
          <template :key="link.title" v-for="link in links">
            <v-list-group :prepend-icon="link.icon" :value="isChildRouteActive(link.children)" v-if="link.children">
              <template #activator>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </template>

              <NavItem :key="sublink.title" subroute :link="sublink" v-for="sublink in link.children" />
            </v-list-group>

            <NavItem :link="link" :is-mini="isMini" @mouseenter="onMouseEnterItem($event, link)" @mouseleave="onMouseLeaveItem" v-else />
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-list class="pt-0 bg-background text-on-background collapse-sidebar--holder overflow-hidden">
      <v-list-item
        density="comfortable"
        :style="`width: ${(isMini ? width.mini : navWidth) - 1}px`"
        class="bg-background text-on-background"
        :prepend-icon="isMini ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'"
        title="Collapse sidebar"
        @click="onToggleNav"
      >
      </v-list-item>
    </v-list>

    <div ref="flyoutEl" :style="flyoutStyles" class="flyout"></div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMenuResize, useSettingsManager } from '@console/composables';
import { trackEvent } from '@console/core/ninja';
import type { LeftNavigationItem } from '@console/types';
import NavItem from './NavItem.vue';

export default defineComponent({
  name: 'LeftNavigation',
  components: {
    NavItem,
  },

  props: {
    items: {
      type: Object as PropType<Record<string, LeftNavigationItem[]>>,
      required: true,
    },
  },
  emits: ['resize'],

  setup(props, { emit }) {
    const route = useRoute();
    const { navWidth, onMouseDown, onMouseUp } = useMenuResize({
      width: 230,
      cacheKey: 'console.leftNav.navWidth',
    });
    const isMini = useSettingsManager<boolean>('leftNav.isMini', false);
    const flyoutEl = ref<HTMLElement>(null);
    const flyoutStyles = ref<Record<string, string>>({});

    const enabledItems = computed(() => {
      return Object.entries(props.items).reduce<typeof props.items>((acc, [key, value]) => {
        return {
          ...acc,
          [key]: value.filter(e => e.enabled()),
        };
      }, {});
    });
    const appName = computed(() => Object.keys(enabledItems.value)[0]);
    const toggleMini = () => {
      isMini.value = !isMini.value;
    };
    const isChildRouteActive = (children: LeftNavigationItem[]) => {
      return children.some(c => (typeof c.to === 'string' ? c.to === route.name : c.to.name === route.name));
    };
    const onToggleNav = () => {
      trackEvent(isMini.value ? 'expand_sidebar' : 'collapse_sidebar');

      toggleMini();
    };
    const onMouseLeaveItem = () => {
      flyoutStyles.value = {};
      flyoutEl.value.textContent = '';
    };
    const onMouseEnterItem = (e: MouseEvent, link: LeftNavigationItem) => {
      if (isMini.value) {
        const el = e.target as HTMLElement;
        const rect = el.getBoundingClientRect();

        flyoutEl.value.textContent = link.title;

        flyoutStyles.value = {
          display: 'flex',
          height: `${rect.height}px`,
          top: `${rect.y}px`,
          left: `${rect.x + rect.width + 7}px`,
        };
      } else {
        onMouseLeaveItem();
      }
    };

    watch(navWidth, value => emit('resize', value));

    return {
      navWidth,
      flyoutEl,
      flyoutStyles,
      isMini,
      enabledItems,
      appName,
      onMouseDown,
      onMouseUp,
      toggleMini,
      onMouseEnterItem,
      onMouseLeaveItem,
      onToggleNav,
      isChildRouteActive,
    };
  },

  data() {
    return {
      drawer: true,
      width: {
        normal: 230,
        mini: 56,
      },
    };
  },
});
</script>

<style lang="scss">
@import '@console/theme/styles/variables';

.console {
  .navigation.navigation--left {
    $collapse-btn-h: 44px;
    z-index: 1000;
    padding-bottom: 0;

    .v-navigation-drawer {
      transition-duration: 0;
    }

    .v-navigation-drawer__content {
      border-right: 1px solid var(--v-surface1);
    }

    .flyout {
      position: absolute;
      display: none;
      background: var(--v-background);
      color: var(--v-on-background);
      align-items: center;
      padding: 12px;
      min-width: max-content;
      max-height: 48px;

      border: 1px solid var(--v-surface1);
      border-left: 0;
      border-radius: 0 $border-radius-root $border-radius-root 0;
      white-space: nowrap;
    }

    .sidebar {
      max-height: calc(100vh - #{$collapse-btn-h} - 64px) !important;
    }

    .navigation__control-item {
      padding: 0 12px;
    }

    &:not(:has(.v-navigation-drawer--mini-variant)) {
      .v-list-item {
        padding-left: 16px;
      }

      .navigation__control-item {
        padding-left: 18px;
      }
    }
  }
}
</style>
