<template>
  <ConsoleApplication :left-nav-items="leftNavItems" v-bind="$props" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useGlobalFilters } from '@console/composables/useGlobalFilters';
import { useProducts } from '@console/composables/useProducts';
import { trackEvent } from '@console/core/ninja';
import { CONSOLE_PRODUCTS, type LeftNavigationItem } from '@console/types';
import { ConsoleApplication, consoleApplicationProps } from '@console/ui/ConsoleApplication';
import { useTitle } from '@vueuse/core';

export default defineComponent({
  name: 'App',
  components: {
    ConsoleApplication,
  },

  props: consoleApplicationProps,

  setup() {
    const title = useTitle();
    const { getProduct } = useProducts();
    const { customerUnitId } = useGlobalFilters();

    const leftNavItems = computed<Record<string, LeftNavigationItem[]>>(() => {
      const app = getProduct(CONSOLE_PRODUCTS.EXPERIMENTS);

      if (app) {
        return {
          [app?.text]: [
            {
              enabled: () => true,
              title: 'About',
              icon: 'mdi-file-document',
              to: {
                name: 'experiments.about',
              },
            },

            {
              title: 'All experiments',
              icon: app?.icon,
              to: {
                name: 'experiments.list',
                params: {
                  cuid: customerUnitId.value.toString(),
                },
              },
              click: () => trackEvent('menu_list_experiments'),
              enabled: () => true,
            },
            {
              title: 'MSS calculator',
              icon: 'mdi-calculator',
              to: {
                name: 'experiments.mss-calculator',
              },
              enabled: () => true,
            },
          ],
        };
      }

      return {};
    });

    title.value = 'Experiments';

    return {
      leftNavItems,
    };
  },
});
</script>
