import { ConsoleRouteConfig } from '@console/router';

export default [
  {
    name: 'experiments.root',
  },
  {
    name: 'experiments.about',
    component: () => import('../pages/ExperimentsAboutPage.vue'),
  },
  {
    name: 'experiments.list',
    component: () => import('../pages/ExperimentsListPage/ExperimentsListPage.vue'),
  },
  {
    name: 'experiments.edit',
    component: () => import('../pages/ExperimentEditPage/ExperimentEditPage.vue'),
  },
  {
    name: 'experiments.new',
    component: () => import('../pages/ExperimentEditPage/ExperimentEditPage.vue'),
  },

  {
    name: 'experiments.report_jira',
    component: () => import('../pages/ExperimentReportPage/ExperimentReportJira.vue'),
  },
  {
    name: 'experiments.report',
    component: () => import('../pages/ExperimentReportPage/ExperimentReportPage.vue'),
  },
  {
    name: 'experiments.report.summary',
    component: () => import('../pages/ExperimentReportPage/Summary/SummaryView.vue'),
  },
  {
    name: 'experiments.report.reports',
    component: () => import('../pages/ExperimentReportPage/Reports/ReportsView.vue'),
  },
  {
    name: 'experiments.report.reports.advanced',
    component: () => import('../pages/ExperimentReportPage/Reports/Advanced/ReportAdvancedView.vue'),
  },
  {
    name: 'experiments.report.reports.segments',
    component: () => import('../pages/ExperimentReportPage/Reports/Segments/ReportSegmentsView.vue'),
  },
  {
    name: 'experiments.report.details',
    component: () => import('../pages/ExperimentReportPage/Details/DetailsView.vue'),
  },
  {
    name: 'experiments.report.discussion',
    component: () => import('../pages/ExperimentReportPage/Discussion/DiscussionView.vue'),
  },
  {
    name: 'experiments.report.changelog',
    component: () => import('../pages/ExperimentReportPage/Changelog/ChangelogView.vue'),
  },

  {
    name: 'experiments.mss-calculator',
    component: () => import('../pages/MSSCalculatorPage/MSSCalculatorPage.vue'),
  },
] as ConsoleRouteConfig[];
